import React from 'react';
import {
    Box,
    Heading,
    Container,
    Text,
} from '@chakra-ui/react';
import { PrismicRichText } from '@prismicio/react';

export default function PageHeadlineContent(props) {
    return (
        <Box px="4">
            <Heading as="h2" mb="8" color="#4c4c4c" fontWeight="900" textAlign={props.textAlign} fontSize="3rem" fontFamily="Libre Caslon Text, serif">
                {props.sectionHeadline}
            </Heading>
            <Container textAlign={props.textAlign} maxW="container.md">
                <PrismicRichText field={props.sectionText} />
            </Container>
        </Box>
    )
}